<template>
  <basic-modal @open="openModal" tag="form" size="md">
    <template #header>
      <div class="close__modal">add song to playlist</div>
    </template>
    <template #default>
      <b-row class="align-items-center">
        <!-- add song -->
        <b-col cols="10">
          <b-form-group label="add songs to play list" label-for="add_song">
            <v-select
              v-model="form.music_id"
              :loading="isLoading"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(val) => val.id"
              :options="musics"
              label="name"
              :clearable="false"
              input-id="add_song"
              @search="filterMusic"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="addMusicToPlayList"
          >
            add
          </b-button>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-start align-items-center">
        <i class="WMi-list-ul-2" />
        {{ form.name }}
      </div>
      <div
        cols="12"
        sm="4"
        md="4"
        v-for="music in form.music"
        :key="music.id"
        class="my-2"
      >
        <b-row>
          <b-col cols="2">
            <b-avatar size="45">
              <div
                class="image"
                :style="{
                  backgroundImage: `url('${music.thumbnail}')`,
                }"
              />
            </b-avatar>
          </b-col>
          <b-col cols="7">
            <div class="truncate">
              <h5 class="mb-25">
                {{ music.name }}
              </h5>
              <span>{{ music.artist.name_ar }}</span>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              @click="deletePlaylistMusic(music.id)"
            >
              <i class="WMi-trash-alt"></i>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>

    <template #footer>
      <div class="d-flex flex-row-reverse align-items-center w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          @click="$_closeModal()"
        >
          ok
        </b-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { ref } from "@vue/composition-api";
import { BButton, BFormGroup, BRow, BCol, BAvatar } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import PlayListViewTabData from "./PlayListViewTabData";
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
import PlayListRepository from "@/abstraction/repository/playListRepository";
const playListRepository = new PlayListRepository();
import MusicRepository from "@/abstraction/repository/musicRepository";
const musicRepository = new MusicRepository();

export default {
  name: "modal_add_song_to_play_list",
  components: {
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BAvatar,
    vSelect,
    PlayListViewTabData,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    Options: [],
    userData: {},
    isLoading: false,
  }),
  methods: {
    openModal({ model }) {
      if (model.id) {
        this.loadPlaylistMusicById(model.id);
      }
    },
    async loadPlaylistMusicById(playlistId) {
      try {
        const response = await playListRepository.show(playlistId);
        this.form = response;
      } catch (error) {
        return error;
      }
    },
    async deletePlaylistMusic(musicId) {
      try {
        const response = await playListRepository.detach(this.form.id, musicId);
        this.form = { ...this.form, ...response };
      } catch (error) {
        return error;
      }
    },
    async addMusicToPlayList() {
      try {
        const response = await playListRepository.attach(
          this.form.id,
          this.form.music_id
        );
        this.form = { ...this.form, ...response };
      } catch (error) {
        return error;
      }
    },
    filterMusic(search) {
      debouncedFunction(search, async () => {
        const filters = {
          name: {
            type: "like",
            val: search,
          },
        };
        const params = {
          filters,
          pagination: {
            page: 1,
          },
        };
        this.isLoading = true;
        try {
          this.loadMusics(params);
        } catch (err) {
          console.log(err, "there was an error loading models");
        } finally {
          this.isLoading = false;
        }
      });
    },
    async loadMusics(param = {}) {
      const resource = await musicRepository.index(param);
      this.musics = resource.data.filter(music=>{
        console.log(this.form.music,music);
        console.log();
        return this.form.music.findIndex(x=>x.id === music.id) === -1;
      });
    },
  },
  setup() {
    const blankForm = {
      music: [],
      music_id: null,
    };
    const form = ref(blankForm);
    const musics = ref([]);
    return {
      form,
      musics,
    };
  },
};
</script>
<style scoped>
.image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
