import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData, setComingSoonData, getComingSoonArray, getComingSoonJson } from "../resources/slideShowResource";

export default class PlayListRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle;
    }

    async index(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-home-slideshow-all", { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async comingSoonIndex(data) {
        const params = setQuery(data);
        const response = await axios.get("api/admin-coming-soon-all", { params });
        if (response.status === 200) {
            return getComingSoonArray(response.data);
        }
    }
    async activeIndex(data) {
        const response = await axios.get("api/admin-home-slideshow-active");
        if (response.status === 200) {
            return response;
        }
    }
    async activeComingSoonIndex(data) {
        const response = await axios.get("api/admin-coming-soon-active");
        if (response.status === 200) {
            return response;
        }
    }
    async show(SlideshowId) {
        const response = await axios.get(url("showSlideshow", { slideshow: SlideshowId }));
        if (response.status === 200) {
            return getJson(response.data.data);
            // return response.data.data
        }
    }
    async showComingSoon(ComingsoonId) {
        const response = await axios.get(url("showComingSoon", { comingsoon: ComingsoonId }));
        if (response.status === 200) {
            return getComingSoonJson(response.data.data);
            // return response.data.data
        }
    }

    async addSlideShow(data) {
        const json = setData(data);
        const response = await axios.post(url("storeSlideShow"), json);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async addComingSoon(data) {
        const json = setComingSoonData(data);
        const response = await axios.post(url("storeComingSoon"), json);
        if (response.status === 201) {
            return getJson(response.data);
        }
    }
    async addActiveSlideShow(data) {
        const response = await axios.post("api/admin-home-slideshow-active", data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async addActiveComingSoons(data) {
        const response = await axios.post("api/admin-coming-soon-active", data);
        if (response.status === 201) {
            return response.data;
        }
    }

    async update(id, data) {
        const json = setData(data);
        const response = await axios.put(url("updateSlideshow", { slideshow: id }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
    async comingSoonUpdate(id, data) {
        const json = setComingSoonData(data);
        const response = await axios.put(url("updateComingSoon", { comingsoon: id }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(url("updateSortPlaylist", { playlist: playlistId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async delete(id) {
        const response = await axios.delete(url("deleteSlideshow", { slideshow: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async deleteComingSoon(id) {
        const response = await axios.delete(url("deleteComingSoon", { comingsoon: id }));
        if (response.status === 200) {
            return true;
        }
    }
    async attach(playlistId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("attachPlaylist", { playlist: playlistId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async detach(playlistId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("detachPlaylist", { playlist: playlistId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
}
